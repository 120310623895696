import { useCallback } from 'react';
import type { MutationHook } from '@commerce/utils/types';
import { CommerceError } from '@commerce/utils/errors';
import useAddItem, { type UseAddItem } from '@commerce/wishlist/use-add-item';
import type { AddItemHook } from '@commerce/types/wishlist';
import useCustomer from '../customer/use-customer';
import useWishlist from './use-wishlist';

export default useAddItem as UseAddItem<typeof handler>;

export const handler: MutationHook<AddItemHook> = {
  fetchOptions: {
    url: '/api/commerce/wishlist',
    method: 'POST',
  },
  useHook:
    ({ fetch }) =>
    () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { data: customer } = useCustomer();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { mutate } = useWishlist();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useCallback(
        async function addItem(item) {
          if (!customer) {
            // A signed customer is required in order to have a wishlist
            throw new CommerceError({
              message: 'Signed customer not found',
            });
          }

          // TODO: add validations before doing the fetch
          const data = await fetch({ input: { item } });
          await mutate();
          return data;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetch, mutate, customer]
      );
    },
};
