import { useCallback } from 'react';
import type { MutationHook } from '@commerce/utils/types';
import { CommerceError } from '@commerce/utils/errors';
import useRemoveItem, {
  type UseRemoveItem,
} from '@commerce/wishlist/use-remove-item';
import type { RemoveItemHook } from '@commerce/types/wishlist';
import useCustomer from '../customer/use-customer';
import useWishlist from './use-wishlist';

export default useRemoveItem as UseRemoveItem<typeof handler>;

export const handler: MutationHook<RemoveItemHook> = {
  fetchOptions: {
    url: '/api/commerce/wishlist',
    method: 'DELETE',
  },
  useHook:
    ({ fetch }) =>
    ({ wishlist } = {}) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { data: customer } = useCustomer();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { mutate } = useWishlist(wishlist);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useCallback(
        async function removeItem(input) {
          if (!customer) {
            // A signed customer is required in order to have a wishlist
            throw new CommerceError({
              message: 'Signed customer not found',
            });
          }

          const data = await fetch({ input: { itemId: String(input.id) } });
          await mutate();
          return data;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetch, mutate, customer]
      );
    },
};
