import { useCallback } from 'react';
import type { MutationHook } from '@commerce/utils/types';
import { CommerceError } from '@commerce/utils/errors';
import useLogin, { UseLogin } from '@commerce/auth/use-login';
import type { LoginHook } from '@commerce/types/login';
import useCustomer from '../customer/use-customer';

export default useLogin as UseLogin<typeof handler>;

export const handler: MutationHook<LoginHook> = {
  fetchOptions: {
    url: '/api/commerce/login',
    method: 'POST',
  },
  async fetcher({ input: { email, password }, options, fetch }) {
    if (!(email && password)) {
      throw new CommerceError({
        message: 'An email and password are required to login',
      });
    }

    return fetch({
      ...options,
      body: { email, password },
    });
  },
  useHook:
    ({ fetch }) =>
    () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { mutate } = useCustomer();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useCallback(
        async function login(input) {
          const data = await fetch({ input });
          await mutate();
          return data;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetch, mutate]
      );
    },
};
