import { useCallback } from 'react';
import type {
  MutationHookContext,
  HookFetcherContext,
} from '@commerce/utils/types';
import { ValidationError } from '@commerce/utils/errors';
import useRemoveItem, { UseRemoveItem } from '@commerce/cart/use-remove-item';
import type { Cart, LineItem, RemoveItemHook } from '@commerce/types/cart';
import useCart from './use-cart';

export type RemoveItemFn<T = any> = T extends LineItem
  ? (input?: RemoveItemActionInput<T>) => Promise<Cart | null | undefined>
  : (input: RemoveItemActionInput<T>) => Promise<Cart | null>;

export type RemoveItemActionInput<T = any> = T extends LineItem
  ? Partial<RemoveItemHook['actionInput']>
  : RemoveItemHook['actionInput'];

export default useRemoveItem as UseRemoveItem<typeof handler>;

export const handler = {
  fetchOptions: {
    url: '/api/bigcommerce/cart',
    method: 'DELETE',
  },
  async fetcher({
    input: { itemId },
    options,
    fetch,
  }: HookFetcherContext<RemoveItemHook>) {
    return await fetch({ ...options, body: { itemId } });
  },
  useHook:
    ({ fetch }: MutationHookContext<RemoveItemHook>) =>
    <T extends LineItem | undefined = undefined>(ctx: { item?: T } = {}) => {
      const { item } = ctx;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { data, mutate } = useCart();
      const removeItem: RemoveItemFn<LineItem> = async (input) => {
        const itemId = input?.id ?? item?.id;

        if (!itemId) {
          throw new ValidationError({
            message: 'Invalid input used for this operation',
          });
        }

        const fetchData = await fetch({ input: { itemId } });
        if (fetchData === null) {
          await mutate();
        } else {
          await mutate(fetchData, false);
        }
        return data;
      };

      // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
      return useCallback(removeItem as RemoveItemFn<T>, [fetch, mutate]);
    },
};
