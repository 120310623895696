import { useCallback } from 'react';
import type { MutationHook } from '@commerce/utils/types';
import { CommerceError } from '@commerce/utils/errors';
import useAddItem, { UseAddItem } from '@commerce/cart/use-add-item';
import type { AddItemHook } from '@commerce/types/cart';
import useCart from './use-cart';

export default useAddItem as UseAddItem<typeof handler>;

export const handler: MutationHook<AddItemHook> = {
  fetchOptions: {
    url: '/api/commerce/cart',
    method: 'POST',
  },
  async fetcher({ input: item, options, fetch }) {
    if (
      item.quantity &&
      (!Number.isInteger(item.quantity) || item.quantity! < 1)
    ) {
      throw new CommerceError({
        message: 'The item quantity has to be a valid integer greater than 0',
      });
    }

    const data = await fetch({
      ...options,
      body: { item },
    });

    return data;
  },
  useHook:
    ({ fetch }) =>
    () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { mutate } = useCart();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useCallback(
        async function addItem(input) {
          const data = await fetch({ input });
          await mutate(data, false);
          return data;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetch, mutate]
      );
    },
};
