import { useMemo } from 'react';
import { SWRHook } from '@commerce/utils/types';
import useWishlist, { type UseWishlist } from '@commerce/wishlist/use-wishlist';
import useCustomer from '../customer/use-customer';

import type { GetWishlistHook } from '@commerce/types/wishlist';

export default useWishlist as UseWishlist<typeof handler>;
export const handler: SWRHook<GetWishlistHook> = {
  fetchOptions: {
    url: '/api/commerce/wishlist',
    method: 'GET',
  },
  async fetcher({ input: { customerId, includeProducts }, options, fetch }) {
    if (!customerId) return null;

    // Use a dummy base as we only care about the relative path
    const url = new URL(options.url!, 'http://a');

    if (includeProducts) url.searchParams.set('products', '1');

    return fetch({
      url: url.pathname + url.search,
      method: options.method,
    });
  },
  useHook:
    ({ useData }) =>
    (input) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { data: customer } = useCustomer();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const response = useData({
        input: [
          ['customerId', customer?.id],
          ['includeProducts', input?.includeProducts],
        ],
        swrOptions: {
          revalidateOnFocus: false,
          ...input?.swrOptions,
        },
      });

      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useMemo(
        () =>
          Object.create(response, {
            isEmpty: {
              get() {
                return (response.data?.items?.length || 0) <= 0;
              },
              enumerable: true,
            },
          }),
        [response]
      );
    },
};
